import { render, staticRenderFns } from "./DocumentHtmlElementEdit.vue?vue&type=template&id=70a864ae&scoped=true&"
import script from "./DocumentHtmlElementEdit.vue?vue&type=script&lang=js&"
export * from "./DocumentHtmlElementEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a864ae",
  null
  
)

export default component.exports